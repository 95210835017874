export default {
  data() {
    return {
      // 激活
      activator: {
        visible: false,
        width: '460px',
        title: '启用可预约，否则不可预约',
        text: '是否启用',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/canteen/account/updateIsActive',
        model: {
          id: undefined,
          status: undefined,
          userid: undefined
        },
        rules: {
          type: { required: true, message: '充值方式是必选的' },
          amount: { required: true, message: '充值金额是必填的' }
        },
        fields: this.getActivatorFields()
      }
    };
  },
  methods: {
    // 充值表单定义
    getActivatorFields() {
      return [
        {
          label: '是否启用',
          prop: 'status',
          type: 'radio-group',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ]
        }
      ];
    },
    // 打开激活窗口
    onOpenActivator(row) {
      const { activator } = this;
      activator.model.id = row.id;
      activator.model.status = row.isActive;
      activator.model.userid = row.userid;
      activator.visible = true;
    }
  }
};
