export default {
  data() {
    return {
      // 设置分组
      group: {
        visible: false,
        width: '460px',
        title: '设置分组',
        text: '设置分组',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/user/updateConsumerInfo',
        model: {
          id: undefined,
          canteenType: 1,
          consumerGroupId: undefined
        },
        rules: {
          canteenType: { required: true, message: '食堂是必填的' },
          consumerGroupId: { required: true, message: '消费分组是必选的' },
          name: { required: true, message: '名称是必填的' }
        },
        // 表单字段
        fields: this.getGroupFields()
      }
    };
  },
  methods: {
    // 分组表单定义
    getGroupFields() {
      return [
        {
          label: '食堂',
          prop: 'canteenType',
          type: 'select',
          clearable: true,
          options: [
            { label: '不设置', value: 0 },
            { label: '一食堂', value: 1 },
            { label: '二食堂', value: 2 }
          ]
        },
        {
          label: '分组',
          prop: 'consumerGroupId',
          placeholder: '请选择要搜索分组',
          type: 'select',
          clearable: true,
          options: [],
          url: '/canteenConsumerGroup/dropDownList',
          mapper(item) {
            return {
              label: item.name,
              value: item.id
            };
          }
        }
      ];
    },
    // 打开分组窗口
    onOpenGroup(row) {
      const { group } = this;
      group.model.id = row.userid;
      group.model.canteenType = row.canteenType;
      group.model.consumerGroupId = row.consumerGroupId;
      group.visible = true;
    }
  }
};
