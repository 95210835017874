export default {
  data() {
    return {
      // 总余额
      balance: {
        total: 0,
        cash: 0,
        subsidy: 0
      }
    };
  },
  computed: {
    // 显示余额提示栏
    balanceTitle() {
      const { total, subsidy, cash, limit, used } = this.balance;
      return `总余额:￥${total.toFixed(
        2
      )}，补贴总余额:￥${subsidy}，现金总余额:￥${cash}，超市总限额￥${limit}，超市总消费￥${used}`;
    }
  },
  methods: {
    // 加载完成设置余额
    onLoaded(e) {
      const { other } = e;
      if (other) {
        // 总余额
        this.balance = {
          total: other.balanceTotal + other.subsidyMoneyTotal,
          subsidy: other.subsidyMoneyTotal,
          cash: other.balanceTotal,
          limit: other.supermarketPayMaxLimit,
          used: other.supermarketPayUse
        };
      }
    }
  }
};
