import request from '@/utils/request';

export default {
  data() {
    return {
      // 关联业务人员
      related: {
        visible: false,
        width: '460px',
        title: '设置关联人员',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/canteen/account/setExclusiveUser',
        model: {
          id: undefined,
          userAccountIds: []
        },
        fields: this.getRelatedFields()
      }
    };
  },
  methods: {
    // 关联业务人员表单定义
    getRelatedFields() {
      return [
        {
          label: '关联人员',
          prop: 'userAccountIds',
          type: 'select',
          size: 'large',
          placeholder: '输入姓名可搜索并添加',
          multiple: true,
          clearable: true,
          options: [],
          loading: false,
          filterable: true,
          remote: true,
          remoteMethod: this.getRelated
        }
      ];
    },
    // 打开关联业务人员窗口，设置查询参数
    onOpenRelated(row) {
      const { related } = this;
      related.model.id = row.id;
      related.visible = true;
    },
    // 打开关联业务人员窗口，填充选项和模型赋值
    async onRelated() {
      const { related } = this;
      const { model, fields } = related;
      const [selection] = fields;
      selection.loading = true;
      // 获得人员
      const result = await request.post('/canteen/account/getAccountList', {
        canteenType: 1,
        exclusiveAccountId: model.id,
        pageSize: 500
      });
      // 人员选项
      const options = result.data.records.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      selection.options = options;
      // 人员赋值，数组
      model.userAccountIds = options.map(item => item.value);
      selection.loading = false;
    },
    // 关闭关联业务人员窗口，清空选项和模型赋值
    onRelatedClear() {
      const { related } = this;
      const [selection] = related.fields;
      related.model.userAccountIds = undefined;
      selection.options = [];
    },
    // 远程搜索关联业务人员
    async getRelated(name) {
      if (!name) {
        return;
      }
      const { related } = this;
      const [selection] = related.fields;
      selection.loading = true;
      // 清空
      selection.options = [];
      const result = await request.post('/canteen/account/getAccountList', {
        name,
        pageSize: 500
      });
      const options = result.data.records.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      // 选项填充
      selection.options = options;
      selection.loading = false;
    }
  }
};
