<template>
  <app-page
    ref="page"
    :toolbar="toolbar"
    :list="list"
    @toolbar-change="onToolbarChange"
    @search="onSearch"
    @loaded="onLoaded"
    @selection-change="onSelectionChange"
  >
    <template #append>
      <el-alert :title="balanceTitle" type="success" :closable="false" />
    </template>
    <template #index>
      <el-table-column type="selection" align="center" />
    </template>
    <template #button="props">
      <el-button
        icon="el-icon-plus"
        :disabled="multiple"
        v-bind="props"
        @click="onOpenRecharges"
      >
        批量充值
      </el-button>
      <el-button
        icon="el-icon-minus"
        :disabled="multiple"
        v-bind="props"
        @click="onOpenDeductions"
      >
        批量扣费
      </el-button>
      <el-button
        icon="el-icon-delete"
        :disabled="multiple"
        v-bind="props"
        @click="onReset"
      >
        批量清零(补贴)
      </el-button>
      <el-button
        v-if="isBussiness"
        icon="el-icon-plus"
        v-bind="props"
        @click="onOpenBusiness"
      >
        新增业务账户
      </el-button>
    </template>
    <template #actions="{ row }">
      <el-dropdown @command="onCammand(row, $event)">
        <el-button type="text">
          更多操作
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="onOpenRecord">消费记录</el-dropdown-item>
          <el-dropdown-item v-show="toolbar.model.canteen !== 2" command="onOpenGroup">分组</el-dropdown-item>
          <el-dropdown-item v-show="toolbar.model.canteen !== 2" divided></el-dropdown-item>
          <el-dropdown-item v-show="toolbar.model.canteen !== 2" command="onOpenRecharge">充值</el-dropdown-item>
          <el-dropdown-item v-show="toolbar.model.canteen !== 2" command="onOpenDeduction">扣费</el-dropdown-item>
          <el-dropdown-item v-if="isBussiness || toolbar.model.canteen !== 2" command="onOpenRelated">
            关联人员
          </el-dropdown-item>
          <el-dropdown-item v-show="toolbar.model.canteen !== 2" command="onOpenActivator">
            是否启用
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template #dialog>
      <app-list-dialog v-bind.sync="record" />
      <app-form-dialog v-bind.sync="group" @confirm="onConfirm" />
      <app-form-dialog v-bind.sync="recharge" @confirm="onConfirm" />
      <app-form-dialog v-bind.sync="deduction" @confirm="onConfirm" />
      <app-form-dialog v-bind.sync="business" @confirm="onConfirm" />
      <app-form-dialog
        v-bind.sync="related"
        @open="onRelated"
        @close="onRelatedClear"
        @confirm="onConfirm"
      />
      <app-form-dialog v-bind.sync="activator" @confirm="onConfirm" />
    </template>
  </app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Account',
  mixins,
  methods: {
    // 确认后刷新，所有模态窗口共用
    async onConfirm() {
      await this.$refs.page.getItems();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-alert__title {
  font-size: 1.5em;
  line-height: inherit;
}
</style>
