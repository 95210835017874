export default {
  data() {
    return {
      // 扣费
      deduction: {
        visible: false,
        width: '460px',
        title: '扣费',
        text: '扣费',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/canteen/recharge/withdraw',
        model: {
          ids: undefined,
          type: 3,
          amount: undefined,
          remark: undefined,
          operateUserId: sessionStorage.getItem('userId')
        },
        rules: {
          type: { required: true, message: '扣费方式是必选的' },
          amount: { required: true, message: '扣费金额是必填的' }
        },
        fields: this.getDeductionFields()
      }
    };
  },
  methods: {
    // 扣费表单定义
    getDeductionFields() {
      return [
        {
          label: '扣费方式',
          prop: 'type',
          type: 'radio-group',
          options: [
            { label: '补贴扣费', value: 2 },
            { label: '现金扣费', value: 3 },
            { label: '提现扣费', value: 4 }
          ]
        },
        {
          label: '扣费金额',
          prop: 'amount',
          type: 'input-number',
          placeholder: '请输入充值金额',
          size: 'large'
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          showWordLimit: true,
          placeholder: '请输入备注',
          rows: 4
        }
      ];
    },
    // 打开扣费窗口，批量
    onOpenDeductions() {
      const { deduction, selectedItems } = this;
      deduction.model.ids = selectedItems.map(item => item.id);
      deduction.visible = true;
    },
    // 打开扣费窗口
    onOpenDeduction(row) {
      const { deduction } = this;
      deduction.model.ids = [row.id];
      deduction.visible = true;
    }
  }
};
