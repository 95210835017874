export default {
  data() {
    return {
      // 新增业务账户
      business: {
        visible: false,
        width: '460px',
        title: '新增业务账户',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/user/createExclusive',
        // 表单
        model: {
          canteenType: 1,
          consumerGroupId: undefined,
          name: undefined,
          departId: undefined
        },
        rules: {
          canteenType: { required: true, message: '食堂是必填的' },
          consumerGroupId: { required: true, message: '消费分组是必选的' },
          name: { required: true, message: '名称是必填的' },
          departId: { required: true, message: '部门是必选的' }
        },
        // 表单字段
        fields: this.getBussinessFields()
      }
    };
  },
  methods: {
    // 业务账号表单定义
    getBussinessFields() {
      return [
        {
          label: '食堂',
          prop: 'canteenType',
          type: 'select',
          clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '一食堂', value: 1 },
            { label: '二食堂', value: 2 }
          ]
        },
        {
          label: '分组',
          prop: 'consumerGroupId',
          placeholder: '请选择分组',
          type: 'select',
          clearable: true,
          options: [],
          url: '/canteenConsumerGroup/dropDownList',
          mapper(item) {
            return {
              label: item.name,
              value: item.id
            };
          }
        },
        { label: '名称', prop: 'name' },
        {
          label: '部门',
          prop: 'departId',
          placeholder: '请选择部门',
          type: 'cascader',
          clearable: true,
          collapseTags: true,
          filterable: true,
          props: {
            emitPath: false,
            expandTrigger: 'hover',
            label: 'name',
            value: 'id'
          },
          showAllLevels: false,
          options: [],
          url: '/department/getList',
          method: 'post'
        }
      ];
    },
    // 打开新增业务账号窗口
    onOpenBusiness() {
      const { business } = this;
      business.visible = true;
    }
  }
};
