import request from '@/utils/request';

export default {
  methods: {
    // 清零
    async onReset() {
      const { $confirm, selectedItems, onConfirm } = this;
      try {
        await $confirm('此操作将选中的人员补贴清零, 是否继续?', '警告', {
          type: 'error'
        });
        const ids = selectedItems.map(item => item.id);
        await request.post('/canteen/account/clear/withdraw', {
          ids,
          type: 5,
          operateUserId: sessionStorage.getItem('userId')
        });
        await onConfirm();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
