import toolbar from './toolbar';
import balance from './balance';
import list from './list';
import bussiness from './bussiness';
import record from './record';
import group from './group';
import recharge from './recharge';
import deduction from './deduction';
import reset from './reset';
import related from './related';
import activator from './activator';

export default [
  toolbar,
  balance,
  list,
  bussiness,
  record,
  group,
  recharge,
  deduction,
  reset,
  related,
  activator
];
