export default {
  data() {
    return {
      // 工具栏设置
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        // 重置忽略的条件
        igrone: ['canteenType', 'isExclusive', 'isActive','canteen'],
        // 搜索条件
        model: {
          name: undefined,
          departmentId: undefined,
          telephone: undefined,
          policeCard: undefined,
          consumerGroupId: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          isExclusive: 0,
          isActive: 1,
          canteen: Number(sessionStorage.getItem('canteenType'))
        },
        // 搜索条件字段
        fields: this.getToolbarFields(),
        // 导出功能
        downloader: {
          text: '导出',
          url: '/canteen/account/exportExcel',
          method: 'post'
        }
      }
    };
  },
  computed: {
    // 是否业务账号
    isBussiness() {
      return this.toolbar.model.isExclusive == 1;
    }
  },
  methods: {
    // 工作栏搜索条件字段定义
    getToolbarFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          placeholder: '请输入要搜索的姓名',
          clearable: true
        },
        {
          label: '部门',
          prop: 'departmentId',
          placeholder: '请选择要搜索的部门',
          type: 'cascader',
          clearable: true,
          collapseTags: true,
          filterable: true,
          props: {
            expandTrigger: 'hover',
            multiple: true,
            label: 'name',
            value: 'id'
          },
          options: [],
          url: '/department/getList',
          method: 'post'
        },
        {
          label: '电话',
          prop: 'telephone',
          placeholder: '请输入要搜索的电话',
          clearable: true
        },
        {
          label: '警号',
          prop: 'policeCard',
          placeholder: '请输入要搜索警号',
          clearable: true
        },
        {
          label: '分组',
          prop: 'consumerGroupId',
          placeholder: '请选择要搜索分组',
          type: 'select',
          clearable: true,
          options: [],
          url: '/canteenConsumerGroup/dropDownList',
          mapper(item) {
            return {
              label: item.name,
              value: item.id
            };
          }
        },
        {
          label: '食堂',
          prop: 'canteenType',
          placeholder: '请选择要搜索食堂',
          type: 'select',
          clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '一食堂', value: 1 },
            { label: '二食堂', value: 2 }
          ]
        },
        {
          label: '业务账号',
          prop: 'isExclusive',
          type: 'radio-group',
          options: [
            { label: '否', value: 0 },
            { label: '是', value: 1 }
          ]
        },
        {
          label: '是否启用',
          prop: 'isActive',
          type: 'radio-group',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ]
        }
      ];
    },
    // 工具栏业务账号切换
    async onToolbarChange(e, field) {
      const { list, getListFields, getBussinessListFields, $refs } = this;
      if (field.prop == 'isExclusive') {
        if (e == 0) {
          list.fields = getListFields();
        } else {
          list.fields = getBussinessListFields();
        }
      }
      await $refs.page.onSearch();
    },
    // 业务账号表格列定义
    getBussinessListFields() {
      return [
        {
          label: '是否启用',
          prop: 'isActive',
          width: 80,
          type: 'switch',
          activeValue: 1,
          inactiveValue: 0
        },
        { label: '名称', prop: 'name', width: 300 },
        {
          label: '部门',
          prop: 'departmentName',
          showOverflowTooltip: true,
          formatter(row) {
            return row.departmentName?.split('、')[0];
          }
        },
        {
          label: '每餐上限',
          prop: 'maxNumber',
          width: 100
        },
        {
          label: '食堂',
          prop: 'canteenType',
          width: 100,
          align: 'center',
          formatter(row) {
            const values = ['', '一食堂', '二食堂'];
            return values[row.canteenType];
          }
        },
        {
          label: '消费分组',
          prop: 'consumerGroupName',
          width: 100,
          align: 'center'
        },
        {
          label: '补贴余额',
          prop: 'subsidyMoney',
          width: 150,
          align: 'right',
          formatter(row) {
            return `￥${row.subsidyMoney}`;
          }
        },
        {
          label: '现金余额',
          prop: 'balance',
          width: 150,
          align: 'right',
          formatter(row) {
            return `￥${row.balance}`;
          }
        },
        {
          label: '总余额',
          prop: 'totalBalance',
          width: 150,
          align: 'right',
          formatter(row) {
            return `￥${row.totalBalance}`;
          }
        }
      ];
    },
    // 搜索，处理项层部门必须为0的问题
    onSearch(model) {
      const { departmentId } = model;
      if (departmentId) {
        const index = departmentId.findIndex(arr => arr.length == 1);
        if (index != -1) {
          const [id] = departmentId[index];
          this.$set(model.departmentId, index, [0, id]);
        }
      }
    }
  }
};
