export default {
  data() {
    return {
      // 选择的项
      selectedItems: [],
      // 列表设置
      list: {
        url: '/canteen/account/getAccountList',
        method: 'post',
        // 列表字段
        fields: this.getListFields()
      }
    };
  },
  computed: {
    // 有了选择才能使用批量操作
    multiple() {
      return this.selectedItems.length == 0;
    }
  },
  methods: {
    // 表格的列定义
    getListFields() {
      return [
        {
          label: '是否启用',
          prop: 'isActive',
          width: 80,
          type: 'switch',
          activeValue: 1,
          inactiveValue: 0,
          headerAlign: 'center'
        },
        { label: '姓名', prop: 'name', width: 120, headerAlign: 'center' },
        {
          label: '部门',
          prop: 'departmentName',
          headerAlign: 'center',
          showOverflowTooltip: true,
          formatter(row) {
            return row.departmentName?.split('、')[0];
          }
        },
        {
          label: '电话',
          prop: 'telephone',
          width: 160,
          headerAlign: 'center'
        },
        {
          label: '警号',
          prop: 'policeCard',
          width: 120,
          headerAlign: 'center'
        },
        {
          label: '食堂',
          prop: 'canteenType',
          width: 100,
          align: 'center',
          formatter(row) {
            const values = ['', '一食堂', '二食堂'];
            return values[row.canteenType];
          }
        },
        {
          label: '消费分组',
          prop: 'consumerGroupName',
          width: 80,
          align: 'center'
        },
        {
          label: '补贴余额',
          prop: 'subsidyMoney',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.subsidyMoney}`;
          }
        },
        {
          label: '现金余额',
          prop: 'balance',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.balance}`;
          }
        },
        {
          label: '总余额',
          prop: 'totalBalance',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.totalBalance}`;
          }
        },
        {
          label: '超市消费限额',
          prop: 'totalBalance',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.supermarketPayMaxLimit}`;
          }
        },
        {
          label: '超市已消费',
          prop: 'supermarketPayUse',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.supermarketPayUse}`;
          }
        }
      ];
    },
    // 多选变更
    onSelectionChange(e) {
      this.selectedItems = e;
    },
    // 执行下拉菜单
    onCammand(row, e) {
      this[e](row);
    }
  }
};
