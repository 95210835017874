import dayjs from 'dayjs';

export default {
  data() {
    return {
      // 消费记录
      record: {
        visible: false,
        title: '查看消费记录(当月)',
        width: '800px',
        params: { userid: undefined, month: dayjs().format('YYYY-MM') },
        url: '/canteen/account/getConsumption',
        method: 'post',
        // 列表字段
        fields: this.getRecordListFields(),
        action: false
      }
    };
  },
  methods: {
    // 消费记录表格的列定义
    getRecordListFields() {
      return [
        { label: '预约日期', prop: 'orderDate' },
        {
          label: '类别',
          prop: 'module',
          width: 120,
          headerAlign: 'center',
          align: 'center',
          formatter(row) {
            const values = {
              A: '订餐预约',
              B: '业务订餐',
              T: '临时预约',
              W: '外卖预约'
            };
            return values[row.module];
          }
        },
        {
          label: '价格',
          prop: 'price',
          width: 120,
          headerAlign: 'center',
          align: 'right',
          formatter(row) {
            return `￥${row.price}`;
          }
        },
        {
          label: '类型',
          prop: 'type',
          width: 120,
          headerAlign: 'center',
          align: 'center',
          colors: ['', 'success', 'danger', 'warning'],
          formatter(row) {
            const values = ['', '早餐', '中餐', '晚餐', '外卖'];
            return values[row.type];
          }
        },
        {
          label: '状态',
          prop: 'status',
          width: 120,
          headerAlign: 'center',
          align: 'center',
          colors: ['', 'primary', 'success', 'info', 'success'],
          formatter(row) {
            const values = ['', '已预约', '已使用', '已取消', '已支付'];
            return values[row.status];
          }
        }
      ];
    },
    // 打开消费记录窗口
    onOpenRecord(row) {
      const { record } = this;
      record.params.userid = row.userid;
      record.visible = true;
    }
  }
};
