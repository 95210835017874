export default {
  data() {
    return {
      // 充值
      recharge: {
        visible: false,
        width: '460px',
        title: '充值',
        text: '充值',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/canteen/recharge/recharge',
        model: {
          ids: undefined,
          type: 0,
          amount: undefined,
          remark: undefined,
          operateUserId: sessionStorage.getItem('userId')
        },
        rules: {
          type: { required: true, message: '充值方式是必选的' },
          amount: { required: true, message: '充值金额是必填的' }
        },
        fields: this.getRechargeFields()
      }
    };
  },
  methods: {
    // 充值表单定义
    getRechargeFields() {
      return [
        {
          label: '充值方式',
          prop: 'type',
          type: 'radio-group',
          options: [
            { label: '现金充值', value: 0 },
            { label: '补贴充值', value: 1 }
          ]
        },
        {
          label: '充值金额',
          prop: 'amount',
          type: 'input-number',
          placeholder: '请输入充值金额',
          size: 'large'
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          showWordLimit: true,
          placeholder: '请输入备注',
          rows: 4
        }
      ];
    },
    // 打开充值窗口，批量
    onOpenRecharges() {
      const { recharge, selectedItems } = this;
      recharge.model.ids = selectedItems.map(item => item.id);
      recharge.visible = true;
    },
    // 打开充值窗口
    onOpenRecharge(row) {
      const { recharge } = this;
      recharge.model.ids = [row.id];
      recharge.visible = true;
    }
  }
};
